import React from 'react'
import cn from 'classnames'
import { useModalActionContext } from 'contexts/modal/ModalContext'
import { REGIWALL } from 'components/shared/Modals/helpers/constants'
import Button from 'components/shared/Buttons/Button'
import { LOGIN_BUTTON_RENDERED_IMPRESSION_NAME } from 'utils/analytics/impressions'
import styles from './headernav.module.scss'
import { stopPropagation } from 'utils/events'
import useAnalytics from 'hooks/useAnalytics'
import { TAP_RECIPE_BOX_INTERACTION_NAME } from 'utils/analytics/interactions'
import SubscribeLink from './SubscribeLink'
import { ReactComponent as AccountIcon } from 'public/assets/icon/account-icon.svg'

export const LoggedOutLinks = ({ isOnSale }: { isOnSale: boolean }) => {
  const { showModal } = useModalActionContext()

  const impressionData = {
    module: { name: LOGIN_BUTTON_RENDERED_IMPRESSION_NAME, label: 'navbar' },
  }
  const { track, fireInteractionEvent } = useAnalytics(impressionData)
  const { impressionRef } = track.impressions()

  const openRegiModal = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    stopPropagation(e)
    showModal({ type: REGIWALL, modalFields: { isCloseable: true } })
  }

  const recipeBoxOnClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    openRegiModal(e)
    fireInteractionEvent({
      name: TAP_RECIPE_BOX_INTERACTION_NAME,
    })
  }

  return (
    <span className={styles['link-group']}>
      <Button
        ref={impressionRef}
        className={cn(styles.headerNavButton, styles.loginButton)}
        appearance={'default'}
        onClick={recipeBoxOnClick}
      >
        Log In
      </Button>
      <SubscribeLink isOnSale={isOnSale} isLoggedIn={false} />

      <Button
        ref={impressionRef}
        className={styles.loginAccountIcon}
        size={'sm'}
        appearance={'default'}
        onClick={recipeBoxOnClick}
        accessibilityText={'Account'}
      >
        <AccountIcon />
      </Button>
    </span>
  )
}

export default LoggedOutLinks
