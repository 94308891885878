import React, { useEffect, useState } from 'react'
import useAnalytics from 'hooks/useAnalytics'
import { getOverrideSaleConfig } from 'utils/sale/saleInfo'
import { CONVERSION_BANNER_IMPRESSION_NAME } from 'utils/analytics/impressions'
import { NYT_PRODUCTION_BASE } from 'utils/environment'
import styles from './systemstatusbanner.module.scss'
import Text from '../Typography/v2/Text'
import Link from '../Link/Link'
import cn from 'classnames'

interface BannerProps {
  isShownBannerBottom?: boolean
}

const ConversionBanner = ({ isShownBannerBottom }: BannerProps) => {
  const [bannerText, setBannerText] = useState(false)
  const bannerUrl = `${NYT_PRODUCTION_BASE}/subscription/cooking?campaignId=8JLL9`

  // Track impressions
  const { track } = useAnalytics({
    eventData: {
      type: 'load',
      trigger: 'module',
    },
    module: {
      name: CONVERSION_BANNER_IMPRESSION_NAME,
      region: 'top',
      label: 'STANDCON_CONV_COOKING_SALE_NAV_BAR_2211',
    },
  })
  const { impressionRef } = track.impressions()

  // Track click event
  const trackBannerOnClick = () => {
    track.outboundClick({
      eventData: {
        type: 'click',
      },
      module: {
        name: CONVERSION_BANNER_IMPRESSION_NAME,
        region: 'top',
        label: 'STANDCON_CONV_COOKING_SALE_NAV_BAR_2211',
        element: {
          name: 'goto-subscribe',
          label: 'Get 50% off your first year of Cooking.',
          url: bannerUrl,
        },
      },
    })
  }

  useEffect(() => {
    // @ts-expect-error
    setBannerText(getOverrideSaleConfig().bannerText)
  }, [])

  return (
    <div
      className={cn(styles.staticConversionBannerContainer, {
        [styles.staticConversionBannerContainerBannerBottom]:
          isShownBannerBottom,
      })}
      ref={impressionRef}
    >
      <Link
        className={cn(styles.conversionBanner, {
          [styles.conversionBannerBottom]: isShownBannerBottom,
        })}
        onClick={trackBannerOnClick}
        href={bannerUrl}
        rel="noreferrer"
      >
        <div className={styles.innerConversionBannerContainer}>
          <Text
            element="span"
            variant="ui"
            className={styles.conversionBannerText}
          >
            <Text
              element="span"
              variant="ui-strong"
              className={styles.conversionBannerUrgency}
            >
              {bannerText}{' '}
            </Text>
            <br className={styles.mobileOnly} />
            Save 50% on your first year of Cooking.
          </Text>
          <Text element="div" className={styles.conversionBannerLinkContainer}>
            <Text className={styles.conversionBannerLink}>Learn more</Text>
            <img
              className={styles.conversionBannerIcon}
              src="https://mwcm.nyt.com/dam/LP/cooking/icons/chevron-right-white.svg"
              alt="Arrow link"
            ></img>
          </Text>
        </div>
      </Link>
    </div>
  )
}

export default ConversionBanner
