import { ReactNode } from 'react'
import Head from 'next/head'
import { useAppContext } from 'contexts/app/AppContext'
import { pageTypes } from 'utils/pages'
import { buildFullUrl, buildSearchUrl } from 'utils/url'
import {
  META_BING_VERIFICATION_CODE,
  META_FB_APP_ID,
  META_IOS_APP_ID,
  META_IOS_APP_NAME,
  META_PLAYSTORE_APP_ID,
  META_PUBLISHER,
  META_SITE_NAME,
  META_TWITTER_CARD,
  META_TWITTER_SITE,
  META_TYPE,
} from './constants'
import { TouchIcons } from './TouchIcons'
import { PageMetadata } from './type'

const FALLBACK_SOCIAL_IMAGE = buildFullUrl('/assets/defaultOg.png')

export type HeadMetaDataProps = {
  meta: PageMetadata
  canonicalUrl?: string
  children?: ReactNode
}

const HeadMetaData: React.FC<HeadMetaDataProps> = ({
  canonicalUrl,
  meta = {},
  children,
}) => {
  let imageUrl = meta.imageUrl || FALLBACK_SOCIAL_IMAGE
  const isSearchPage = meta.pageType && meta.pageType === pageTypes.SEARCH
  const { lastPage, query, queryParams = {} } = meta.searchParams || {}
  const { page: currentPage } = queryParams

  const generateRobotsTag = () => {
    switch (meta.pageType) {
      case pageTypes.RECIPE:
        return (
          <meta name="robots" content="noarchive, max-image-preview:large" />
        )
      case pageTypes.SEARCH:
        return (
          <meta
            name="robots"
            content="noindex, follow, max-image-preview:large"
          />
        )
      case pageTypes.RECIPE_BOX_COOKED:
      case pageTypes.RECIPE_BOX_FOLDER:
      case pageTypes.RECIPE_BOX_RECENTLY_VIEWED:
      case pageTypes.RECIPE_BOX_SAVED:
        return <meta name="robots" content="noindex" />
      case pageTypes.ARTICLE:
      case pageTypes.BYLINE_PAGE:
      case pageTypes.COLLECTION:
      case pageTypes.GUIDES:
      case pageTypes.HOME:
        return <meta name="robots" content="max-image-preview:large" />
      default:
        return ''
    }
  }
  const generateTitle = () => {
    if (meta.pageType && meta.pageType === pageTypes.HOME) {
      return `${META_SITE_NAME} - ${meta.title}`
    }
    let paginationTitle = ''
    if (currentPage && currentPage > 1) {
      paginationTitle = `Page ${currentPage} of `
    }
    if (meta.disableTitleBranding) {
      return `${paginationTitle}${meta.title}`
    }
    return `${paginationTitle}${meta.title} - ${META_SITE_NAME}`
  }

  let ogUrl = canonicalUrl
  if (meta.isSocialGift) {
    const u = new URL(imageUrl)
    u.searchParams.append('gift', 'recipe')
    imageUrl = u.toString()
    meta.socialTitle = `${meta.socialTitle} (Gift Recipe)`
    ogUrl = `${canonicalUrl}?unlocked_article_code=1`
  }
  const appContext = useAppContext()

  const socialDescription = meta.socialDescription || meta.description

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <title>{generateTitle()}</title>

        {/* SEO Description */}
        {meta.description && (
          <meta name="description" content={meta.description} />
        )}

        <link rel="shortcut icon" href="/favicon.ico" />

        {/* Canonical URL */}
        <link rel="canonical" href={canonicalUrl} />

        {/* Robots */}
        {generateRobotsTag()}

        {/* Pagination Links */}
        {isSearchPage && currentPage && lastPage && currentPage < lastPage && (
          <link
            rel="next"
            href={buildFullUrl(
              // @ts-expect-error
              buildSearchUrl(query, { ...queryParams, page: currentPage + 1 }),
            )}
          />
        )}
        {isSearchPage && currentPage && currentPage !== 1 && (
          <link
            rel="prev"
            href={buildFullUrl(
              // @ts-expect-error
              buildSearchUrl(query, { ...queryParams, page: currentPage - 1 }),
            )}
          />
        )}

        {/* Web App Manifest Banner for Android Devices */}
        <link rel="manifest" href="/manifest.json" />

        {/* Smart App Banner */}
        <meta
          name="apple-itunes-app"
          content={`app-id=${META_IOS_APP_ID}, app-argument=${META_IOS_APP_NAME}://cooking.nytimes.com${
            meta.path || ''
          }`}
        />

        {/* OG Tags */}
        <meta property="og:url" content={ogUrl} />
        <meta property="og:title" content={meta.socialTitle} />
        <meta property="og:image" content={imageUrl} />
        {socialDescription && (
          <meta property="og:description" content={socialDescription} />
        )}
        <meta property="og:type" content={meta.type ? meta.type : META_TYPE} />
        <meta property="og:site_name" content={META_SITE_NAME} />

        {/* Facebook */}
        <meta property="article:publisher" content={META_PUBLISHER} />
        <meta property="fb:app_id" content={META_FB_APP_ID} />

        {/* Twitter */}
        <meta name="twitter:title" content={meta.socialTitle} />
        {socialDescription && (
          <meta name="twitter:description" content={socialDescription} />
        )}
        <meta name="twitter:image" content={imageUrl} />
        <meta name="twitter:image:alt" content={meta.title} />
        <meta name="twitter:site" content={META_TWITTER_SITE} />
        <meta
          name="twitter:card"
          content={
            meta.twitter && meta.twitter.cardType
              ? meta.twitter.cardType
              : META_TWITTER_CARD
          }
        />
        {meta.twitter && meta.twitter.cardType === 'app' && (
          <>
            <meta name="twitter:app:id:iphone" content={META_IOS_APP_ID} />
            <meta name="twitter:app:id:ipad" content={META_IOS_APP_ID} />
            <meta
              name="twitter:app:id:googleplay"
              content={META_PLAYSTORE_APP_ID}
            />
          </>
        )}

        {/* NYT Event Tracker */}
        {meta.uri && (
          <meta
            data-rh="true"
            name="nyt_uri"
            itemProp="identifier"
            content={meta.uri}
          />
        )}
        {meta.pubpEventId && (
          <meta
            data-rh="true"
            name="pubp_event_id"
            itemProp="identifier"
            content={meta.pubpEventId}
          />
        )}
        <meta name="CG" content="npv" />
        <meta name="SCG" content="cooking" />
        <meta name="PT" content={meta.pageType} />
        <meta name="sourceApp" content={appContext?.name} />

        {/* Bing Verification */}
        <meta name="msvalidate.01" content={META_BING_VERIFICATION_CODE} />

        {/* JSON-LD */}
        {meta.jsonLD && (
          <script
            type="application/ld+json"
            // This instance of dangerouslySetInnerHTML is not sanitized as that renders the jsonLD unparsable
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(meta.jsonLD),
            }}
          />
        )}

        {/* Children */}
        {children && children}
      </Head>
      <TouchIcons />
    </>
  )
}

export default HeadMetaData
