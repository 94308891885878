import { $TSFixMe } from 'types'
import getDateOverride from '../getDateOverride'

// Adjusted for Daylight Savings Time

/** @param {number|null} overrideDate Override date supplied by a date override parameter (if present) */
// Sale start date
const saleStartDate = new Date('11/13/2023 06:00:00 GMT-0500').getTime()
// Sale end date
const saleEndDate = new Date('12/26/2023 11:00:00 GMT-0500').getTime()

/**
 * Make sure the "startDate" is on DESC order
 */
const SALE_INFO_OVERRIDE_CONFIG = [
  {
    bannerText: 'Final Hours.',
    headerText: 'Final hours',
    offerText: 'The Holiday Sale final hours.',
    startDate: new Date('12/25/2023 18:00:00 GMT-0500').getTime(),
  },
  {
    bannerText: 'Ends Today.',
    headerText: 'Ends today',
    offerText: 'The Holiday Sale ends today.',
    startDate: new Date('12/25/2023 06:00:00 GMT-0500').getTime(),
  },
  {
    bannerText: 'Ends Tomorrow.',
    headerText: 'Ends tomorrow',
    offerText: 'The Holiday Sale ends tomorrow.',
    startDate: new Date('12/24/2023 06:00:00 GMT-0500').getTime(),
  },
  {
    bannerText: 'Ends Soon.',
    headerText: 'Ends soon',
    offerText: 'The Holiday Sale ends soon.',
    startDate: new Date('12/15/2023 06:00:00 GMT-0500').getTime(),
  },
  {
    bannerText: 'Don’t miss the best of Cookie Week.',
    headerText: 'Ends soon',
    offerText: 'The Holiday Sale won’t last.',
    startDate: new Date('12/07/2023 06:00:00 GMT-0500').getTime(),
  },
  {
    bannerText: 'Offer Won’t Last.',
    headerText: 'Limited time offer',
    offerText: 'The Holiday Sale won’t last.',
    startDate: new Date('11/24/2023 06:00:00 GMT-0500').getTime(),
  },
  {
    bannerText: 'Last Chance Before Thanksgiving.',
    headerText: 'Limited time offer',
    offerText: 'The Holiday Sale won’t last.',
    startDate: new Date('11/17/2023 06:00:00 GMT-0500').getTime(),
  },
  {
    bannerText: 'The Holiday Sale Is On.',
    headerText: 'Limited time offer',
    offerText: 'The Holiday Sale is on.',
    startDate: new Date('11/13/2023 06:00:00 GMT-0500').getTime(),
  },
]

/**
 * Get the sale message configuration date based
 *
 * @param {string} searchString - The search string to parse (optional)
 * @returns {object|undefined} - sale message configuration
 */
export function getOverrideSaleConfig(date: $TSFixMe) {
  const searchString = window.location.search
  // Uses the value of the `now` URL param during testing
  // @ts-expect-error
  const overrideDate = new Date(getDateOverride(searchString)).getTime() || null
  const currentDate = overrideDate || date || new Date().getTime()

  const overrideSaleConfig = SALE_INFO_OVERRIDE_CONFIG.find(
    ({ startDate }) => currentDate >= startDate && currentDate < saleEndDate,
  )
  return overrideSaleConfig || {}
}

/* Whether the current date or given date is on Cooking sale
 * @returns {boolean}
 */
export const isSaleActive = (date: $TSFixMe) => {
  const searchString = window.location.search
  // Uses the value of the `now` URL param during testing
  // @ts-expect-error
  const overrideDate = new Date(getDateOverride(searchString)).getTime() || null
  const currentDate = overrideDate || date || new Date().getTime()
  return currentDate >= saleStartDate && currentDate < saleEndDate
}
