/**
 * Returns the value of a date to simulate. Gets the contents of the `now` url parameter.
 * @returns
 * @example
 * https://cooking.local.nytimes.com/recipes/1019077-banana-chocolate-chip-cake?now=2022-11-03T10:00:01
 */

export default function getDateOverride(url: string) {
  const search = url || window.location.search
  const urlParam = 'now'
  const urlParams = new URLSearchParams(search)
  return urlParams.get(urlParam) || null
}
