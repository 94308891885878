import React, { PropsWithChildren } from 'react'

import styles from './pagegrid.module.scss'

type PageGridProps = PropsWithChildren

const PageGrid: React.FC<PageGridProps> = ({ children }) => (
  <div className={styles.container}>{children}</div>
)

export default PageGrid
