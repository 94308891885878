import React from 'react'
import Button from 'components/shared/Buttons/Button'
import { getSubscriptionURLForHeader, SUBSCRIBE_LINK_TEXT } from '../constants'
import { SUBSCRIBE_BUTTON_IMPRESSION_NAME } from 'utils/analytics/impressions'
import useAnalytics from 'hooks/useAnalytics'
import cn from 'classnames/bind'
import styles from './headernav.module.scss'
import { TAP_SUBSCRIBE_INTERACTION_NAME } from 'utils/analytics/interactions'
import { useAppContext } from 'contexts/app/AppContext'
import { isThirdPartyPaywall } from 'utils/subscriptions'
import Text from 'components/shared/Typography/v2/Text'

const cx = cn.bind(styles)

interface SubscribeLinkPropTypes {
  isOnSale: boolean
  isLoggedIn: boolean
}

const SaleText = () => {
  const { track } = useAnalytics({
    eventData: { type: 'load', trigger: 'module' },
    module: { name: TAP_SUBSCRIBE_INTERACTION_NAME, label: 'navbar' },
  })

  const { impressionRef } = track.impressions()
  return (
    <span ref={impressionRef}>
      <Text element="span">Subscribe for 50% off</Text>
    </span>
  )
}
const SubscribeLink = ({ isOnSale, isLoggedIn }: SubscribeLinkPropTypes) => {
  const { track } = useAnalytics({
    module: { name: SUBSCRIBE_BUTTON_IMPRESSION_NAME },
  })
  const { newSubscriptionURL } = useAppContext()
  const subscriptionURL = getSubscriptionURLForHeader(newSubscriptionURL)

  const { impressionRef } = track.impressions()
  return (
    <Button
      element="a"
      ref={impressionRef}
      isExternal={isThirdPartyPaywall(subscriptionURL)}
      className={cx(styles.subscribeButton, styles.headerNavButton, {
        [styles.isLoggedIn]: isLoggedIn,
      })}
      appearance={'primary'}
      href={subscriptionURL}
      onClick={() => {
        track.outboundClick({
          module: {
            name: TAP_SUBSCRIBE_INTERACTION_NAME,
            label: 'navbar',
          },
        })
      }}
    >
      {isOnSale ? <SaleText /> : SUBSCRIBE_LINK_TEXT}
    </Button>
  )
}

export default SubscribeLink
